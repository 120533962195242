import Vue from 'vue'
import router from './router'
import axios from 'axios'
import App from './App'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.use(ElementUI)

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.prototype.backendURL = 'https://lytbasketball.fun/backend/rank'
Vue.prototype.wsURL = 'wss://lytbasketball.fun/ws/rank'
Vue.prototype.wxURL = 'https://lytbasketball.fun/user/userinfo'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
