<template>
  <div>
    <div class="paihang">
      <h1>排行榜</h1>
    </div>
    <div class="container">
      <table>
        <thead>
        <tr>
          <th>头像</th>
          <th>排名</th>
          <th>用户名</th>
          <th>分数</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(user, index) in userList" :key="index">
          <td>
            <img :src="user.headImgUrl" alt="picture" style="width: 100px; height: 100px;"/>
          </td>
          <td>{{ user.rank }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.score }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="userinfo">
      <table>
        <tbody>
          <img :src="this.headImgUrl" alt="头像" style="width: 100px; height: 100px;border-radius: 50%"/>
          <span>昵称： {{ this.username }}</span>
          <br>
          <span>用户Id: {{ this.userId }}</span>
        </tbody>
      </table>
    </div>
    <div class="row-center">
      <div>ICP备案编号：</div>
      <div class="icp" @click="toICP">粤ICP备2024166534号-2</div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      websocket: null,
      userList: [],
      // 用户信息
      userId: '',
      username: '',
      headImgUrl: ''
    }
  },
  beforeMount () {
    if (!localStorage.getItem('remindExecuted')) {
      this.remind()
      localStorage.setItem('remindExecuted', 'true')
    }
  },

  mounted () {
    this.userinfo()
    this.getRankFirst()
    this.fetchUserList()
  },
  methods: {
    remind () {
      console.log('进入提醒')
      this.$notify({
        title: '注意！！！',
        message: '请点击下方授权获取完整服务'
      })
    },
    getRankFirst () {
      this.$axios({
        method: 'get',
        url: `${this.backendURL}`
      }).then(res => {
        this.userList = res.data
      }).catch(err => {
        console.log(err)
      })
    },
    fetchUserList () {
      const url = `${this.wsURL}`
      this.websocket = new WebSocket(url)
      // 监听socket连接
      this.websocket.onopen = this.open
      // 监听socket错误信息
      this.websocket.onerror = this.error
      // 监听socket消息
      this.websocket.onmessage = this.getMessage
      console.log('进入消息监听')
      console.log(this.websocket.onmessage)
    },
    userinfo () {
      const openId = this.$route.query.openId
      this.$axios({
        method: 'get',
        url: `${this.wxURL}` + '/' + openId
      }).then(res => {
        this.userId = res.data.userId
        this.username = res.data.username
        this.headImgUrl = res.data.headImgUrl
      })
    },
    open () {
      console.log('socket连接成功')
    },
    getMessage (message) {
      console.log(message.data)
      this.userList = JSON.parse(message.data)
    },
    error () {
      console.log('连接错误')
    },
    toICP() {
      window.open('https://beian.miit.gov.cn/')
    }
  }
}
</script>

<style>
.paihang{
  text-align: center;
  justify-content: center;
}
.container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
}
.userinfo{
  margin-left: 5em;
}

.row-center {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.icp {
  cursor: pointer;
}

</style>
