import Vue from 'vue'
import VueRouter from 'vue-router'
import SortView from '../views/SortView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/*',
    component: SortView,
    props: (route) => ({
      deviceId: route.query.deviceId,
      openId: route.query.openId
    })
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
